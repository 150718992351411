<template>
  <vs-row v-if="products" justify="center">
    <v-slide-group show-arrows>
      <v-slide-item v-for="item in shuffleArray(products)" v-bind:key="item.productId" style="padding: 0 1px;">
        <router-link style="text-decoration: none" :to="{ name: 'ViewProduct', params: { productId: item.productId } }">
          <v-card :width="$vuetify.breakpoint.smAndDown ? 250 : 350" height="350" tile flat>
            <v-img height="100%" v-bind:src="item.productImages[0]">
              <template v-slot:placeholder>
                <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
              </template>
            </v-img>
          </v-card>
          <div style="padding: 2% 0;">
            <h5> {{ item.productTitle }} </h5>
            <h6> <i class="fa-solid fa-bangladeshi-taka-sign"></i><strong> {{ item.productPrice }} </strong></h6>
          </div>
        </router-link>
      </v-slide-item>
    </v-slide-group>
  </vs-row>
</template>

<script>
export default {
  name: 'YouMayLike',
  props: ['products'],
  data() {
    return {
    };
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
  },
};
</script>
